<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Entente sur la sécurité au laboratoire</h2>

      <p class="mb-n3">
        J’ai lu attentivement l’article intitulé "La sécurité dans un laboratoire de chimie".
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        J’ai lu attentivement l’article intitulé "Les premiers soins au laboratoire de chimie".
      </p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        J’ai pris connaissance de l’emplacement des douches oculaires, des douches de sécurité, des
        extincteurs et de la trousse de premiers soins.
      </p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p style="font-weight: bold" class="mb-2">Je m’engage à porter :</p>

      <ul class="mb-3">
        <li>des lunettes de sécurité (ou des lunettes étanches)</li>
        <li>un sarrau</li>
        <li>des vêtements couvrant les jambes entièrement</li>
        <li>des souliers qui couvrent les pieds entièrement</li>
      </ul>

      <p class="mb-n3" style="font-weight: bold">
        en tout temps pendant les séances de laboratoire, sauf si j’ai reçu du démonstrateur la
        permission expresse de les enlever.
      </p>

      <v-radio-group v-model="inputs.input4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        J’ai lu et compris les articles intitulés "La sécurité dans un laboratoire de chimie" et
        "Les premiers soins au laboratoire de chimie", et je m’engage à suivre les règles et
        procédures décrits dans ces articles et dans la présente entente. Je m’engage également à me
        conformer à tout autre règlement ou directive qui me seront communiqués par mon
        démonstrateur ou une autre personne responsable du laboratoire de chimie.
      </p>

      <v-radio-group v-model="inputs.input5" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3" style="font-weight: bold">
        Je comprends les conditions concernant la remise des rapports en retard et je m’engage à me
        conformer à ces conditions.
      </p>

      <v-radio-group v-model="inputs.input6" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0 pt-2">
        Veuillez apposer vos initiales dans l'espace ci-dessous. Ces initiales remplaceront la date
        et la signature pour indiquer que vous respecterez pleinement les déclarations auxquelles
        vous avez adhéré ci-dessus.
      </p>

      <p>
        Les initiales :
        <v-text-field
          v-model="inputs.initials"
          style="max-width: 100px"
          class="d-inline-flex centered-input"
        />
        &nbsp;&nbsp;&nbsp;&nbsp; La date :
        <input v-model="inputs.signingDate" type="date" min="2024-09-01" max="2030-12-31" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'uOttawaSafetyWaiverFr',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        initials: null,
        signingDate: null,
      },
      options: [{text: "Je suis d'accord", value: 'agree'}],
    };
  },
  computed: {
    today() {
      return new Date().toJSON().slice(0, 10);
    },
  },
};
</script>
